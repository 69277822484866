@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900,900i|Roboto:100,400&display=swap');



@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/raleway/Raleway-Regular.woff') format('woff'), url('../fonts/raleway/Raleway-Regular.ttf')  format('truetype'), url('../fonts/raleway/Raleway-Regular.svg#Raleway-Regular') format('svg');
    font-weight: normal;
    font-style: normal; }


@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-Italic.eot?#iefix') format('embedded-opentype'),  url('../fonts/raleway/Raleway-Italic.woff') format('woff'), url('../fonts/raleway/Raleway-Italic.ttf')  format('truetype'), url('../fonts/raleway/Raleway-Italic.svg#Raleway-Italic') format('svg');
    font-weight: normal;
    font-style: italic; }


@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/raleway/Raleway-Medium.woff') format('woff'), url('../fonts/raleway/Raleway-Medium.ttf')  format('truetype'), url('../fonts/raleway/Raleway-Medium.svg#Raleway-Medium') format('svg');
    font-weight: 500;
    font-style: normal; }


@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-MediumItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/raleway/Raleway-MediumItalic.woff') format('woff'), url('../fonts/raleway/Raleway-MediumItalic.ttf')  format('truetype'), url('../fonts/raleway/Raleway-MediumItalic.svg#Raleway-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic; }


@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/raleway/Raleway-Bold.woff') format('woff'), url('../fonts/raleway/Raleway-Bold.ttf')  format('truetype'), url('../fonts/raleway/Raleway-Bold.svg#Raleway-Bold') format('svg');
    font-weight: 600;
    font-style: normal; }


@font-face {
    font-family: 'Raleway';
    src: url('../fonts/raleway/Raleway-Black.eot?#iefix') format('embedded-opentype'),  url('../fonts/raleway/Raleway-Black.woff') format('woff'), url('../fonts/raleway/Raleway-Black.ttf')  format('truetype'), url('../fonts/raleway/Raleway-Black.svg#Raleway-Black') format('svg');
    font-weight: 900;
    font-style: normal; }



.action-button {
    display: inline-block;
    position: relative;
    padding: 2rem 7rem;
    font-weight: 900;
    font-size: 1.2rem;
    color: #fff;
    background: #ff5858;
    background: linear-gradient(150deg, #ff5858 0%, #f09819 100%);
    border-radius: 3px;
    transition: all .3s;
    overflow: hidden;
    box-shadow: 0 3px 15px rgba(#000, .3);

    span {
        position: relative;
        z-index: 2;
        color: #fff;
        transition: all .3s; }

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        background: linear-gradient(150deg, #f09819 0%, #ff5858 100%);
        transition: opacity .3s;
        left: 0;
        top: 0;
        height: 120%;
        width: 100%;
        opacity: 0; }



    &:hover,
    &:active {
        text-decoration: none;
        color: #fff;
        // background: #f09819
        background: linear-gradient(150deg, #ff5858 0%, #f09819 100%);
        // transform: translateY(-2px)
        box-shadow: 0 3px 20px rgba(#000, .4);

        &:after {
            opacity: 1; }

        span {
 } } }            // opacity: .8

.mt20 {
    margin-top: 20; }

.mt40 {
    margin-top: 40; }

.center {
    text-align: center; }
