#main-wrapper {
    background-image: url(../img/mipomnim_bg.png); }


body {
    @media (max-width: 767px) {
        background-image: url(../img/bg.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat; } }

.bg-video {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;

    @media (min-aspect-ratio: 16/9) {
        width: 100%;
        height: auto; }

    @media (max-aspect-ratio: 16/9) {
        width: auto;
        height: 100%; }

    @media (max-width: 767px) {
        display: none; } }


#main-wrapper {
    background: none; }

.form__wrapper {
    box-shadow: 0 2rem 5rem rgba(0,0,0,.4); }

.header {
    padding: 1.5rem 0;
    height: 11rem;
    background: rgba(#fff, .9);

    &__logo {
        width: 20rem; }

    &__top-nav {
        display: none;
        position: fixed;
        overflow: auto;
        top: 0;
        left: 0;
        background: linear-gradient(150deg, #811223 0%, #32273e 100%);
        width: 100%;
        height: 100%;
        z-index: 1000;
        opacity: .97;

        &_active {
            display: block; } }

    .section {
        position: relative;
        height: 100%; }

    &__upload-video {
        position: absolute;
        right: 12rem;
        top: 1.3rem;
        background-color: #a98857;
        color: #dccfbd;
        // border-radius: 3px
        padding: 1.5rem 4rem;
        font-size: 1.4rem;
        box-shadow: 0 0.5rem 2rem rgba(0,0,0,.3);
        transition: all .3s;

        &:hover,
        &:focus {
            text-decoration: none;
            color: #dccfbd;
            box-shadow: 0 0.8rem 2.5rem rgba(0,0,0,.3);
            transform: translateY(-3px); }


        @include respond (tab-port) {
            right: 3rem;
            top: auto;
            bottom: -9rem;
            padding-left: 2rem;
            padding-right: 2rem; } }


    &__logos {
        float: left;
        height: 100%; }

    &__logo {
        display: inline-block;
        vertical-align: top;
        height: 100%;
        width: auto;
        margin-right: 20px;

        @include respond (tab-port) {
            margin-right: 10px; }

        img {
            height: 100%;
            width: auto; } }

    &__rmu {
        margin-top: .5rem; }

    &__hashteg {
        margin-top: 1em;

        @include respond (tab-port) {
            position: absolute;
            left: 3rem;
            bottom: -12rem; } } }


.hashteg {
    font-weight: 600;
    font-size: 1.4rem;

    span {
        color: inherit; } }


.top-nav {
    position: absolute;
    width: 100%;
    top: 10rem;
    padding-bottom: 10rem;

    &>li {
        position: relative;
        padding: 0;
        margin: 0;
        display: block;
        text-align: center; }

    &>li>a {
        text-transform: uppercase;
        font-weight: 900;
        transition: all .3s;
        font-size: 1.4rem;
        padding: 1rem 0;
        display: block;
        color: #fff;

        &:hover,
        &:active {
            color: #fff;
            text-decoration: none; } } }


.hamburger {
    display: block;
    position: absolute;
    z-index: 3;
    top: 1rem;
    right: 2rem;
    z-index: 10000;

    &:focus {
        outline: none; } }

.section {
    width: 140rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 3rem;


    &__videos {
        margin-top: 15rem;
        width: 120rem;
        max-width: 100%; } }




.videos {
    &__item {
        width: 80rem;
        max-width: 100%;
        margin-bottom: 12rem;

        opacity: 0;
        transform: translateY(50px);
        transition: all .9s;

        @include respond (laptop) {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5rem; }

        &.fade-in {
            opacity: 1;
            transform: translateY(0); }

        &:nth-of-type(even) {
            margin-left: 40rem;

            @include respond (laptop) {
                margin-left: auto; } } }


    &__video {
        width: 100%;
        height: auto;
        box-shadow: 0 2rem 5rem rgba(0,0,0,.4);

        &:focus {
            outline: none; } }

    &__title {
        font-size: 2.1rem;
        color: #fff;
        font-weight: 600;
        opacity: .8;
        margin: 1rem 0 0 3rem;

        display: none; }

    &__subtitle {
        color: #fff;
        opacity: .6;
        font-size: 1.4rem;
        margin-left: 3rem;

        display: none; } }



/* =============== */
/* =============== */

.upload-video {
    background-color: #a98857;
    color: #dccfbd;
    border-radius: 3px;
    padding: 1.5rem 4rem;
    font-size: 1.4rem;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,.3);
    transition: all .3s;

    &:hover,
    &:focus {
        text-decoration: none;
        color: #dccfbd;
        box-shadow: 0 0.8rem 2.5rem rgba(0,0,0,.3);
        transform: translateY(-3px); } }

.first-screen {
    min-height: 100vh;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 15rem;
    overflow: hidden;

    &__upload-video {
        position: absolute;
        right: 3rem;
        top: 4rem; }

    &__rmu {
        position: absolute;
        left: 3rem;
        top: 2rem;
        width: 7.5rem; }

    &__logo {
        width: 60%;
        position: relative;
        left: 50%;
        // top: 10%
        margin-top: 15rem;
        transform: translateX(-50%);
        z-index: 10;


        @include respond (tab-land) {
            margin-top: 20%; }

        @include respond (phone) {
            width: 85%;
            margin-top: 23rem; } }


    &__logo-img {
        // opacity: 0
        transition: all 1s;
        animation: moveInLeft 1.5s  ease-out .7s;
        animation-fill-mode: backwards; }

    &__hashteg {
        position: absolute;
        left: 13rem;
        top: 3rem;
        opacity: .8;

        @include respond (phone) {
            left: 3rem;
            top: 12rem; }

        .hashteg {
            color: #fff;
            line-height: 1.3;
            opacity: .9;


            &:first-child {
                font-size: 2.1rem; } } }


    &__text {
        padding-left: 23%;
        opacity: .7;
        margin-top: -4rem;
        // opacity: 0

        transition: all 1s;
        animation: moveInLeft 1.5s  ease-out 1.2s;
        animation-fill-mode: backwards;

        @include respond (tab-land) {
            margin-top: 0;
            padding-left: 0; }

        @include respond (phone) {
            margin-top: 5rem; }

        * {
            color: #fff;
            font-size: 1.4rem;
            line-height: 1.8; } } }


.gribbon {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 30%; }


.video-list {
    &__wrap {
        width: 100%;
        margin: 0 auto;
        padding: 0 5rem; }

    &__video-link {
        position: relative;
        display: block;
        transition: all .3s;

        &:hover,
        &:focus {
            box-shadow: 0 10px 20px rgba(#000, .2);
            transform: translateY(-3px); } }

    &__play {
        position: absolute;
        width: 4rem;
        height: 4rem;
        // left: 50%
        left: 3rem;
        top: 3rem;
        // transform: translate(-50%, -50%)
        opacity: 1; } }

.masonry-grid {
    &__item {
        width: calc(25% - 30px);
        float: left;
        margin-bottom: 3rem;
        transition: all 1s;
        transform: translateY(50px);
        opacity: 0;

        &.fade-in {
            opacity: 1;
            transform: translateY(0); }

        @include respond (tab-land) {
            width: calc(33% - 30px); }

        @include respond (tab-port) {
            width: calc(50% - 30px); }

        @include respond (phone) {
            width: calc(100%); }

        h2 {
            text-transform: none;
            margin-top: 1.5rem;
            letter-spacing: normal;
            font-size: 1.8rem;
            margin-bottom: 0;
            font-weight: 600; }

        p {
            font-size: 1.4rem;
            opacity: .5;
            line-height: 1.4;
            margin-top: 5px; } } }

.bottom-text {
    margin: 5rem 0 7rem;
    min-height: 40px;
    opacity: .6;

    span {
        display: block;
        text-align: center;
        font-weight: 600;
        opacity: 0;
        font-size: 1.6rem;
        letter-spacing: 1px;
        transition: all 1s;

        &.fade-in {
            opacity: 1;
            transform: translateY(0); } } }

.section {
    &__video {
        padding: 10rem 0;
        background: rgb(248,232,219);
        background: linear-gradient(245deg, rgba(248,232,219,1) 100%, rgba(215,197,185,1) 100%); } }


.partners {
    border-top: 4px solid #333;
    padding-top: 8rem;

    &__title {
        text-align: center; }

    &__list {
        text-align: center;
        font-size: 0; }

    &__item {
        display: inline-block;
        vertical-align: top;
        margin: 3rem 3rem 0;
        height: 6rem;

        @include respond (tab-port) {
            width: calc(33% - 60px); }

        @include respond (phone) {
            width: calc(50% - 60px); }

        a {
            display: block;
            height: 100%;
            filter: grayscale(100%);
            transition: all .3s;

            &:hover,
            &:focus {
                filter: grayscale(0); } }

        img {
            height: 100%; } } }




.videos__video {
    // width: 800px
    // height: auto
 }    // max-width: 90%

.fancybox-content {
    width: 900px !important;
    height: auto !important; }
