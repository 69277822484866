.fade-in-out {
    transition: all .7s;
    opacity: 0;
    transform: translateX(-50px);

    &.fade-in {
        opacity: 1;
        transform: translateX(0); }

    @media (max-width: 992px) {
        transform: translateX(0); } }



// @keyframes homeElements
//     0%
//         opacity: 0
//
//     100%
//         opacity: 1
//
//
// @keyframes homeLogo
//     0%
//         transform: translate(-70%, -55%)
//         opacity: 0
//
//     25%
//         opacity: 1
//
//     40%
//         opacity: 1
//         transform: translate(-70%, -55%)
//
//     100%
//         transform: translate(-100%, -55%)



@keyframes spinner {
  to {
      transform: rotate(360deg); } }


@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-50px); }

    80% {
        transform: translateX(10px); }

    100% {
        opacity: 1;
        transform: translateX(0); } }

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(49%); }

    80% {
        transform: translateX(50.5%); }

    100% {
        opacity: 1;
        transform: translateX(50%); } }
